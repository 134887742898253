import React from 'react'
import Layout from '../../components/layout'

import { Link } from 'gatsby';
import qsafe_features from '../../data/qsafe_features.json';
import qsafe_why from '../../data/qsafe_why.json';
import { StaticImage } from "gatsby-plugin-image"
import Seo from '../../components/seo';

export default function QSafe() {
    const qsafe_hero = '../../assets/images/products/qsafe5.png';
    const qsafe_hero2 = '../../assets/images/products/qsafe3.png';

    return (
        <Layout >
            <Seo title="QSafe" />
            <div className="flex items-center qsafe-hero-card m-4 lg:m-16 lg:mt-10 2xl:m-28 h-full relative z-30 overflow-visible relative">
                {/* <div className="flex hero-card p-4 lg:p-16 lg:mt-10 h-full relative z-30 overflow-visible relative"> */}
                <div id="qsafe_hero" className="z-30 pb-10 md:pb-20">
                    {/* w-1/3 sm:w-4/12 lg:w-4/12 2xl:w-1/5 2.5xl:w-3/12 3xl:w-1/5 */}
                    {/* <main className="mt-10 w-full sm:w-8/12 md:w-7/12 lg:w-8/12 xl:w-7/12 px-6 sm:px-8 lg:px-16 sm:mt-12 md:mt-16 lg:mt-20 xl:mt-28"> */}
                    <main className="mt-10 w-full px-6 sm:px-8 lg:px-16 sm:mt-12 md:mt-16 lg:mt-20 xl:mt-28">
                        <div className="text-center md:text-left">
                            <h1 className="font-extrabold text-dark-tf text-3xl sm:text-4xl md:text-5xl lg:text-5xl xl:text-6xl w-fulltracking-wide ">
                                <span className="block xl:inline leading-relaxed md:leading-14 lg:leading-15">Upgrade your contact tracing forms with <strong className="text-accent-tf">QSafe</strong></span>
                                {/* <span className="block text-blue-tf xl:inline leading-relaxed md:leading-14 lg:leading-15"> TimeFree</span> */}
                                {/* <span className="block xl:inline leading-relaxed md:leading-14 lg:leading-15"> digital solutions</span> */}
                            </h1>
                            <p className="mt-5 sm:mt-5 md:mt-10 lg:mt-15 pl-1 font-normal text-base text-notsodark-tf text-lg md:text-xl xl:text-2xl 4xl:text-3xl lg:mx-0 leading-relaxed md:leading-loose xl:leading-relaxed">
                                a digital contact tracing solution that enables customers to conveniently fill out a contact tracing form by scanning a QR code. It also makes it easy for businesses to generate contact tracing reports.
                            </p>
                        </div>
                        <div className="mt-8 flex lg:mt-10 lg:flex-shrink-0 justify-center md:justify-start">
                            <div className="inline-flex rounded-md shadow-xl shadow-accent-tf/20">
                                <Link to="/contact" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-lg md:text-xl 4xl:text-3xl font-medium rounded-md text-gray-50 bg-accent-tf hover:bg-qsafe-darkgreen">
                                    Request a demo
                                </Link>
                            </div>
                        </div>
                    </main>
                </div>
                {/* <div id="qsafe_hero_image" className="filter drop-shadow-xl px-auto flex justify-end overflow-visible absolute top-20 xxs:top-16 sm:top-0 md:top-10 lg:top-10 2xl:top-10 3xl:-top-20 right-0 lg:right-5 w-1/3 sm:w-4/12 lg:w-4/12 2xl:w-1/5 2.5xl:w-3/12 3xl:w-1/5 hidden sm:block md:block z-10 opacity-50 xs:opacity-100"> */}
                <div id="qsafe_hero_image" className="filter drop-shadow-xl flex self-center overflow-visible w-full 2.5xl:w-1/2 3xl:w-1/2 4xl:w-1/3 hidden md:block z-10 opacity-50 xs:opacity-100">
                    <StaticImage
                        className="flex object-contain w-full"
                        src={qsafe_hero}
                        placeholder="tracedSVG"
                        alt=""
                    />
                </div>
            </div>
            <div id="qsafe_hero_image_alt" className="block md:hidden w-full flex justify-center">
                <StaticImage
                    className="flex object-contain overflow-visible w-1/2"
                    src={qsafe_hero}
                    placeholder="tracedSVG"
                    alt=""
                />
            </div>
            <div id="qsafe_features" className="bg-white py-8 mt-10 ">
                <div className="flex flex-col items-center m-4 lg:m-16 2xl:mx-28">
                    <div className="w-3/4 md:w-5/7">
                        <h4 className="text-3xl 4xl:text-4xl font-bold text-dark-tf text-center">No more manual contact tracing forms.</h4>
                        <p className="text-xl 4xl:text-2xl text-dark-tf text-center mt-3">In the new normal, your business needs to show even more that you truly care about your customers’ safety. Manual contact tracing forms are inconvenient and can be a point of virus transmission as customers share a pen to fill out the forms.</p>
                    </div>
                    <div className="mt-16 flex-none lg:flex">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 flex justify-between items-stretch">
                            {qsafe_features.map((item) => (
                                <div key={item.title}>
                                    <div className="text-center md:text-left bg-qsafe-green p-6 rounded-2xl self-stretch h-full md:flex gap-x-5">
                                        <div className="flex justify-center md:items-start">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-qsafe-darkgreen" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                {item.svg.map((svg, i) => (
                                                    <path key={i.toString()} strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.2} d={svg} />
                                                ))}
                                            </svg>
                                        </div>
                                        <div className="">
                                            <h3 className="head-font font-semibold text-xl md:text-xl 4xl:text-3xl text-qsafe-darkgreen">{item.title}</h3>
                                            <p className="font-normal text-lg 4xl:text-2xl mt-2 text-gray-700">{item.subtitle}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="filter drop-shadow-xl h-1/2">
                            <StaticImage
                                className="flex object-contain overflow-visible h-1/2"
                                src={qsafe_hero2}
                                placeholder="tracedSVG"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div id="qsafe_why" className="mx-4 lg:mx-16 my-28 2xl:mx-28">
                <div className="flex flex-col items-center ">
                    <div className="w-full">
                        <h4 className="text-3xl 4xl:text-4xl font-bold text-dark-tf text-center">Why do businesses choose <strong className="text-accent-tf">QSafe</strong>?</h4>
                    </div>
                    <div className="mt-10 flex-none lg:flex">
                        {/* <div className="filter drop-shadow-xl">
                            <img
                                className="flex object-contain overflow-visible h-full"
                                src={qsafe_hero}
                                alt=""
                            />
                        </div> */}
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 flex justify-between items-stretch ">
                            {qsafe_why.map((item) => (
                                <div key={item.key}>
                                    <div className="text-center md:text-left p-6 rounded-2xl self-stretch h-full gap-x-5">
                                        <div className="flex justify-center md:items-start">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-accent-tf" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                {item.svg.map((svg, i) => (
                                                    <path key={i.toString()} strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.2} d={svg} />
                                                ))}
                                            </svg>
                                        </div>
                                        <div className="text-center mt-4">
                                            <p className="font-medium text-lg 4xl:text-2xl mt-1 text-gray-700">{item.subtitle}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div id="qsafe_cta" className="bg-white">
                <div className="max-w-4xl mx-4 md:mx-auto py-12 px-4 lg:py-16 lg:px-16 lg:flex lg:items-center lg:justify-between">
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl text-center md:text-left">
                        <span className="block text-gray-700">Want to see this in action?</span>
                        <span className="block text-accent-tf "><Link to="/contact">Request a demo now.</Link></span>
                    </h2>
                    <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0 justify-center md:justify-start">
                        <div className="inline-flex rounded-md shadow-xl shadow-accent-tf/20">
                            <Link to="/contact" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-lg md:text-xl 4xl:text-3xl font-medium rounded-md text-gray-50 bg-accent-tf hover:bg-qsafe-darkgreen">
                                Contact us
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
